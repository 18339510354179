<!--
    简单的一个滑动验证码。
    @isOk  成功返回true，重置后返回false
    $refs.xxxx.resets()   重置方法。
 -->
<template>
  <div class="slide">
    <div class="out" :style="{width:width}">
      <p v-show="!isOk">请向右滑动滑块</p>
      <div @mousedown="downJiantou($event)" class="jiantou">
        <p>>></p>
        <div class="green" :style="{width:width}">
          <p v-show="isOk">验证成功</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "aqy-slide",
  props: {
    mobile:{
      type:String|Number
    },
		emptyStr:{
			type:String,
			default:'',
		},
    width:{
      type:String,
      default:'440px',
    }
  },
  data() {
    return {
      isDown: false,
      initX: -1,
      pageX: null,
      isOk: false,
      S: null,
      move: null,
      datas1: {
        x: "",
        y: "",
        time: "",
      },
      datas2: {
        x: "",
        y: "",
        time: "",
      },
    };
  },
  methods: {
    resets() {
      this.isDown = false;
      this.pageX = null;
      this.isOk = false;
      this.S = null;
      this.move = null;
      this.initX = -1;
      for (let key in this.datas1) {
        this.datas1[key] = "";
        this.datas2[key] = "";
      }
      document.querySelector(".jiantou").style.left = "-1px";
      this.$emit("isOk", { isOk: false });
    },
    // 鼠标按下箭头触发
    downJiantou(e) {
      if (!this.isOk) {
        if (!this.mobile) {
          return this.$message.error(this.emptyStr||"请先填写手机号");
        }

        this.datas1.x = e.clientX;
        this.datas1.y = e.clientY;
        this.datas1.time = new Date().getTime();

        this.isDown = true;
        this.pageX = e.pageX;
        //增加事件机制
        document.body.addEventListener("mouseup", this.upJiantou);
        // 由于无法直接拿到event ，所以借用vue的data来转换。
        document.body.addEventListener(
          "mousemove",
          (this.move = (event) => {
            this.moveJantou(event);
          })
        );
      }
    },
    upJiantou() {
      this.isDown = false;
      if (!this.isOk) {
        document.querySelector(".jiantou").className = "jiantou transition";
        document.querySelector(".jiantou").style.left = "-1px";
        setTimeout(() => {
          document.querySelector(".jiantou").className = "jiantou";
        }, 500);
      }
      document.body.removeEventListener("mousemove", this.move);
      document.body.removeEventListener("mouseup", this.upJiantou);
    },
    moveJantou(event) {
      if (this.isDown) {
        this.S = event.pageX - this.pageX + this.initX;
        if (this.S < 0) {
          document.querySelector(".jiantou").style.left = "-1px";
          return;
        }
        if (this.S > 390) {
          this.isDown = false;
          this.isOk = true;
          this.$emit("isOk", {
            data1: this.datas1,
            data2: this.datas2,
            isOk: true,
          });
          document.querySelector(".jiantou").style.left = "390px";
          this.datas2.x = event.clientX;
          this.datas2.y = event.clientY;
          this.datas2.time = new Date().getTime();
        } else {
          document.querySelector(".jiantou").style.left = this.S + "px";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.slide {
  width: 100%;
  .out {
    z-index: 999;
    overflow: hidden;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    margin: 0px auto;
    width: 440px;
    height: 40px;
    border: 1px solid #dcdfe6;
    background-color: #eee;
    user-select: none;
    font-size: 14px;
    text-align: center;
    line-height: 40px;
    color: #666;
    .transition {
      transition: 0.5s;
    }
    .jiantou {
      position: absolute;
      box-sizing: border-box;
      top: -1px;
      left: -1px;
      width: 50px;
      height: 40px;
      border: 1px solid #ccc;
      background-color: #fff;
      & > p {
        user-select: none;
        line-height: 38px;
        text-align: center;
        color: #ccc;
      }
      &:hover {
        cursor: move;
      }
      .green {
        cursor: default;
        width: 440px;
        height: 40px;
        background-color: #7ac23c;
        position: absolute;
        left: -393px;
        top: -1px;
        z-index: -1;
        & > p {
          font-size: 14px;
          color: #fff;
          line-height: 40px;
          text-align: center;
        }
      }
    }
  }
}
</style>
