<template>
<div class="login">
	<loginHeader></loginHeader>

		
		<div id="loginBg" class="body">
		  <el-carousel indicator-position="outside" class="carousel-box" height="100%" :interval="3000">
		    <el-carousel-item v-for="(item,index) in loginImgArr" :key="index">
		      <img :src="item.attachment_path"  style="height: 100%;width:100%;object-fit: cover;">
		    </el-carousel-item>
		  </el-carousel>
		  <div class="content">
		    <!-- 第一步 -->
		    <div class="form-box" v-if="step==1">
		      <div class="title">重置密码</div>
		      <el-form class="form-input-box" :model="form" ref="editForm" :rules="rules">
		        <div>
		          <el-form-item prop="mobile">
		            <el-input placeholder="请输入手机号" v-model.number="form.mobile">
		              <div slot="prepend">+86</div>
		            </el-input>
		          </el-form-item>
		          <el-form-item>
		            <huakuai :mobile="form.mobile" @isOk="isOk" ref="huakuai" />
		          </el-form-item>
		          <el-form-item prop="mobile_verity_code">
		            <div class="code-box">
		              <el-input
		                style="margin-right: 20px"
		                v-model="form.mobile_verity_code"
		                placeholder="请输入验证码"
		              ></el-input>
		              <el-button
		                @click="getCodes"
		                class="get-code-btn"
		                :disabled="disable"
		                style="width: 160px"
		                >{{ showWorld }}</el-button
		              >
		            </div>
		          </el-form-item>
		        </div>
		      </el-form>
		      <el-button type="danger" class="step-btn" @click="nextBtn">下一步</el-button>
		    </div>
		    <!-- 第二步 -->
		    <div class="form-box" v-if="step==2">
		      <div class="title">重置密码</div>
		      <el-form class="form-input-box" ref="pwd" :model="form" :rules="pwdRules">
		        <div>
		          <el-form-item prop="password">
		            <div class="info">密码为8-16位字母/数字/符号，不能是纯数字</div>
		            <el-input placeholder="请输入新的登录密码"
		              type="password"
		              v-model="form.password">
		            </el-input>
		          </el-form-item>
		          <el-form-item prop="password_confirm">
		            <el-input
		              v-model="form.password_confirm"
		              type="password"
		              placeholder="请再次输入密码"
		            ></el-input>
		          </el-form-item>
		        </div>
		      </el-form>
		      <el-button type="danger" class="step-btn" @click="submitBtn">确定</el-button>
		    </div>
		  </div>
		</div>
		<footers :navBar="navBar" />
</div>
</template>

<script>
  import loginHeader from '@/components/header/loginHeader.vue';
  import footers from "@/components/footers.vue";
  import huakuai from "@/views/login/loginHome/huakuai.vue";
	import { isMobile } from "@/utils/wxConfig.js";
	export default {
    components:{
      loginHeader,
      footers,
      huakuai,
    },
		data(){
      let _this = this;
      let rules = {
        mobile:[{require:true,
          trigger:'blur',
          validator:(rules,value,call)=>{
            let regPwd = /(?=.*[0-9])(?=.*[a-zA-Z]).{8,16}/;
            if(!value){
              call(new Error("手机号码不能为空"))
            }else if(!_this.$utils.regPhone(value)){
              call(new Error("请输入正确的手机号码"))
            }else {
              call()
            }
          }
        }],
        mobile_verity_code:[{require:true,
          trigger:'blur',
          validator:(rules,value,call)=>{
            let regPwd = /(?=.*[0-9])(?=.*[a-zA-Z]).{8,16}/;
            if(!value){
              call(new Error("短信验证码不能为空"))
            }else {
              call()
            }
          }
        }]
      }

      let pwdRules={
        password:[{require:true,
          trigger:'blur',
          validator:(rules,value,call)=>{
            let regPwd = /(?=.*[0-9])(?=.*[a-zA-Z]).{8,16}/;
            if(!value){
              call(new Error("密码不能为空"))
            }else if(!regPwd.test(value)){
              call(new Error("密码为8-16位字母/数字/符号，不能是纯数字"))
            }else {
              call()
            }
          }
        }],
        password_confirm:[{require:true,
          trigger:'blur',
          validator:(rules,value,call)=>{
            let regPwd = /(?=.*[0-9])(?=.*[a-zA-Z]).{8,16}/;
            if(!value){
              call(new Error("密码不能为空"))
            }else if(!regPwd.test(value)){
              call(new Error("密码为8-16位字母/数字/符号，不能是纯数字"))
            }else if(_this.form.password!=_this.form.password_confirm) {
              call("两次密码不相同，请重新输入")
            }else {
              call()
            }
          }
        }],
      }
			return {
        loginImgArr:[],
        navBar:[],
        saveData:{},
        step:1,
        disable:true,
        timers:null,
        n:60,
        form:{
          mobile:'',
          mobile_verity_code:'',
          password:'',
          password_confirm:'',
        },
        showWorld:'点击获取验证码',
        pwdRules,
        rules,
				isApp:false,
			}
		},
		created(){
			this.isApp = isMobile();
      this.getBgImg();
		},
		mounted(){
			if(this.isApp){
				// 1rem  相当于750尺寸设计图的 20px;
				(function(){
					var html=document.querySelector('html');
					html.style.fontSize=window.innerWidth/750 * 20 +'px'
					window.onresize=function(){
						html.style.fontSize=window.innerWidth/750 * 20 +'px'
					}
				})();
				var fun = function (doc, win) {
				    var docEl = doc.documentElement,
				        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
				        recalc = function () {
				            var clientWidth = docEl.clientWidth; 
				            if (!clientWidth) return;
				            //这里是假设在750px宽度设计稿的情况下，1rem = 100px；
				            //可以根据实际需要修改
				            docEl.style.fontSize = 20 * (clientWidth / 750) + 'px';
				        };
				    if (!doc.addEventListener) return;
				    win.addEventListener(resizeEvt, recalc, false);
				    doc.addEventListener('DOMContentLoaded', recalc, false);
				}
				fun(document, window)
			}
		},
		methods: {
      isOk(data){
        this.saveData = data;
        this.disable = false;
      },
      submitBtn(){
        this.$refs.pwd.validate(valid=>{
          if(valid){
            let form = this.form;
            this.$axios.postJSON('/base/user/findpwd',form).then(res=>{
              if(res.code==200){
                this.$message.success("修改密码成功，请重新登录");
                setTimeout(()=>{
                  this.$router.push("/login");
                },500)
              }
            }).catch(err=>{
              // console.log(err);
              err.msg && this.$message.error(err.msg)
            })
          }
        })
      },
      nextBtn(){
        this.$refs.editForm.validate(valid=>{
          if(valid){
            this.step = 2;
          }
        })
      },
      getCodes(){
        let val = this.saveData;
        let parma = {
          mobile: Number(this.form.mobile),
          first: {
            x: val.data1.x,
            y: val.data1.y,
            time: val.data1.time,
          },
          second: {
            x: val.data2.x,
            y: val.data2.y,
            time: val.data2.time,
          },
        };

        this.$axios.postJSON("/base/user/findpwd/send_code", parma).then((res) => {
          if (res.code == 200) {
            this.$message.success("获取成功");
            const that = this;
            this.timers = setInterval(() => {
              that.n--;
              if (that.n < 1) {
                clearInterval(that.timers);
                that.$refs.huakuai.resets();
                that.showWorld = "点击获取验证码";
                that.n = 60;
              } else {
                that.showWorld = that.n + "秒后再次获取";
                that.disable = true;
              }
            }, 1000);
          }
        },(err) => {
          this.$refs.huakuai.resets();
        });
      },
      getBgImg(){
        this.$axios.get("/setting/public/reglogin") .then((res) => {
            if (res.code == 200) {
              let data = res.data;
              let loginSet = {},loginImgArr = [];
              data.map(item=>{
                //背景图片
                if(item.key == 'login_reg_pic_coll'){
                  let dataArr = item.value.items;
                  for(let i=0,len=dataArr.length;i<len;i++){
                    if(dataArr[i].status == 1){
                      loginSet = dataArr[i];
                      loginImgArr.push(dataArr[i])
                    }
                  }

                }
                //底部导航
                if(item.key=='login_reg_bottom_nav'){
                  if(item.value && item.value.items && item.value.items instanceof Array){
                    this.navBar = item.value.items.filter(its=>its.status==1);
                  }
                }
              })
              console.log("loginImgArr: ",loginImgArr);
              if(loginImgArr.length>0){

                this.loginImgArr =  loginImgArr
              }
            }
          }).catch((err) => {
            console.log(err);
            err.msg && this.$message.error(err.msg || "系统错误,联系管理员");
          });
      },
		},
	}
</script>

<style scoped lang="scss">
.login{
  display: flex;
  flex-direction: column;
  height: 100vh;
  .body {
    width: 100%;
    flex: 1;
    position: relative;
    .carousel-box{
      height: 100%;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar{
        display: none;
      }
    }
    .content {
      height: 100%;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 100;
      flex:0;
      .form-box{
        background-color: #FFFFFF;
        border-radius: 5px;
        width:560px;
        height: 456px;
        padding:30px;
        padding-top:50px;
        box-sizing: border-box;
      }
      .title{
        font-size: 20px;
        color:#F72626;
        width:440px;
        margin:0 auto 60px;
        position: relative;
        &::after{
          content:'';
          display: block;
          width:4em;
          height: 2px;
          background-color:#F72626;
          position: absolute;
          left:0;
          bottom:-6px;
        }
      }
      .get-code-btn{
        color:#F72626;
        border:1px solid #F72626;
      }
      .form-input-box{
        width:440px;
        height: 166px;
        margin:0 auto;
        .info{
          color:#999999;
          font-size: 14px;
        }
      }
      .code-box{
        display: flex;
      }
      .step-btn{
        width: 440px;
        margin: 50px auto 0;
        display: block;
        // height: 30px;
        // line-height: 60px;
      }
    }


  }
}
</style>
