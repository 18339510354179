<template>
  <div :class="['footer', isApp ? 'app' : '']">
    <div v-if="isApp" class="app-content">
      <ul v-if="!$store.state.finger">
        <li
          v-for="(item, index) in navBar"
          :key="index"
          @click="navBarBtn(index)"
        >
          {{ item.title }}
        </li>
      </ul>
      <div v-html="copyright" style="padding:6px 0" class="copyright"></div>
      <p @click="gotoGongxinbu" class="hovers">{{ beianhao }}</p>
    </div>
    <div class="content" v-else>
      <ul v-if="!$store.state.finger">
        <li
          v-for="(item, index) in navBar"
          :key="index"
          @click="navBarBtn(index)"
        >
          {{ item.title }}
        </li>
      </ul>
      <div v-html="copyright" style="padding:6px 0"></div>
      <p @click="gotoGongxinbu" class="hovers">{{ beianhao }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      yuming: "",
      beianhao: "",
      copyright: "",
      isApp: false,
    };
  },
  props: {
    navBar: {
      type: Array,
      default: () => {
        return [
          {
            title: "关于我们",
          },
          {
            title: "法律声明",
          },
          {
            title: "隐私政策",
          },
          {
            title: "联系我们",
          },
          {
            title: "商务合作",
          },
        ];
      },
    },
  },
  created() {
    this.getBottomInfo();
    this.isApp = this.isMobile();
  },
  methods: {
    isMobile() {
      var userAgentInfo = navigator.userAgent;
      var mobileAgents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var mobile_flag = false;
      for (var v = 0; v < mobileAgents.length; v++) {
        if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
          mobile_flag = true;
          break;
        }
      }
      var screen_width = window.screen.width;
      var screen_height = window.screen.height;
      if (screen_width < 500 && screen_height < 800) {
        mobile_flag = true;
      }
      return mobile_flag;
    },
    gotoGongxinbu() {
      window.open("https://beian.miit.gov.cn", "_blank");
    },
    navBarBtn(index) {
      let item = this.navBar[index];
      if (item.url) {
        window.open(item.url, "_blank");
      }
    },
    getBottomInfo() {
      this.$axios.get("/setting/page_footer").then((res) => {
        if (res.code == 200) {
          let data = res.data;
          let dataObj = data.detail_set;
          if (dataObj.copyright) {
            this.copyright = dataObj.copyright;
          }
        }
      });
      this.$axios.get("/base/beian").then((res) => {
        if (res.code == 200) {
          let data = res.data;
          this.beianhao = data.beian;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 126px;
  background-color: #ffffff;
  &.app {
    height: auto;
  }
  > .content {
    height: 100%;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #666;
    > ul {
      display: flex;
      align-items: center;
      font-size: 16px;
      margin-top: 30px;
      > li {
        line-height: 20px;
        cursor: pointer;
        margin-right: 40px;
        margin-left: 40px;
        cursor: pointer;
      }
      > li:hover {
        color: #f74848;
      }
    }
    > p {
      line-height: 20px;
    }
  }
  .app-content {
    font-size: calc(100vw / 750 * 20);
    padding: 1.25em 1.5em;
    ul {
      display: flex;
      justify-content: center;
      li {
        font-size: 1.4em;
      }
      li + li {
        margin-left: 2em;
      }
    }
    .copyright {
      margin-top: 0.75em;
      font-size: 1.3em;
      text-align: center;
    }
    .hovers {
      margin-top: 0.75em;
      font-size: 1.3em;
      text-align: center;
    }
  }
}
.hovers {
  cursor: pointer;
  &:hover {
    color: #0088fe;
  }
}
</style>
