import Axios from '@/server/ajax.js';
export function wxShareConfige(url) {
    return new Promise((reslove) => {
		//生产环境请求接口读取微信配置
		if(process.env.NODE_ENV == 'production'){
			Axios.postJSON("/msg/jssdkApi", {
				url: url ? url : location.href,
			}).then((res) => {
				if (res.code == 200) {
					// 初始化wx
					wx.config({
							debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
							appId: res.data.appId, // 必填，公众号的唯一标识
							timestamp: res.data.timestamp, // 必填，生成签名的时间戳
							nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
							signature: res.data.signature, // 必填，签名
							jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareWeibo', 'onMenuShareTimeline','onMenuShareAppMessage'], // 必填，需要使用的JS接口列表
					});
					wx.ready(function () {
						console.log('wx ready');
						reslove('1')
					});
					wx.error(function (res) {
						console.log('wx error');
							reslove('0')
					});
				}
			}).catch(err=>{
				reslove('0')
			})
		}else{ //开发环境和测试环境不请求微信配置
			reslove('1')
		}
    })
};

export function shareWXQQ(datas) {
    console.log(location);
    wx.updateAppMessageShareData({
        title: datas.title, // 分享标题
        desc: datas.info, // 分享描述
        link: location.href,
        imgUrl: datas.img_path,
        success: function () {},
        fail: function (error) {
            console.log(error);
        },
    });
    wx.updateTimelineShareData({
        title: datas.title, // 分享标题
        link: location.href,
        imgUrl: datas.img_path,
        success: function () {
            // 设置成功
        },
        fail: function (error) {
            console.log(error);
        },
    });
    wx.onMenuShareTimeline({
        title: datas.title, // 分享标题
        link: location.href,
        imgUrl: datas.img_path,
        success: function () {
            // 用户点击了分享后执行的回调函数
        },
    });
    wx.onMenuShareAppMessage({
        title: datas.title, // 分享标题
        desc: datas.info, // 分享描述
        link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: datas.img_path, // 分享图标
        type: 'link', // 分享类型,music、video或link，不填默认为link
        dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
        success: function () {
          // 用户点击了分享后执行的回调函数
        }
      });
}
export function isMobile(){
	var userAgentInfo = navigator.userAgent;			 
	var mobileAgents = [ "Android", "iPhone", "SymbianOS", "Windows Phone", "iPad","iPod"];	 
	var mobile_flag = false;		 
	//根据userAgent判断是否是手机
	for (var v = 0; v < mobileAgents.length; v++) {
	    if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
	        mobile_flag = true;
	        break;
	    }
	}	 
	 var screen_width = window.screen.width;
	 var screen_height = window.screen.height;   
	 //根据屏幕分辨率判断是否是手机
	 if(screen_width < 500 && screen_height < 800){
	     mobile_flag = true;
	 }
	 return mobile_flag;
}